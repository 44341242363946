import React from "react";

const Page404 = () => {
  return (
    <div style={classes.container}>
      <p style={classes.h1}>404</p>
    </div>
  );
};

const classes = {
  container: {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  h1:{
    fontSize: "6rem"
  }
};

export default Page404;
