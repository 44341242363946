import React from "react";
import { sendQuestion } from "../../api/contacts";
import { useUrlParams } from "../../hooks/path";
import { useTranslate } from "../../i18n";
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_KEY } from "../../constants";
import { useNotifications } from "../../hooks/notification";

const Status = {
  loading: "Loading",
  idle: "idle",
  success: "success"
}

const HelpUs = () => {
  const { lang } = useUrlParams();
  const getWord = useTranslate(lang);
  const [form, setForm] = React.useState({ username: "", email: "", question: "", captcha: "" });
  const [showSuccess, setShowSuccess] = React.useState(Status.idle);
  const { pushMessage } = useNotifications(3000);

  const handleFormInputs = (key, value) => {
    setForm(p => ({ ...p, [key]: (value) }))
  }
  const handleReCaptchValue = (value) => {
    setForm(p => ({ ...p, captcha: (value) }))
  }
  const handleFormSubmission = async () => {
    if (form.username === "" || !form.username) {
      pushMessage(getWord({ key: "required", params: { field: getWord({ key: "name" }) } }));
      return;
    }
    if (form.email === "" || !form.email) {
      pushMessage(getWord({ key: "required", params: { field: getWord({ key: "email" }) } }))
      return;
    }
    if (form.question === "" || !form.question) {
      pushMessage(getWord({ key: "required", params: { field: getWord({ key: "question" }) } }));
      return;
    }
    setShowSuccess(Status.loading)
    const res = await sendQuestion(form);
    console.log(res);
    if (res && !res.success) {
      pushMessage(res.message);
      setShowSuccess(Status.idle);
    } else if (res && res.success) {
      setShowSuccess(Status.success);
      setForm({ username: "", email: "", question: "", captcha: "" });
      setTimeout(() => {
        setShowSuccess(Status.idle);
      }, 4000);
    } else {
      pushMessage("error");
      setShowSuccess(Status.idle);
    }
  }

  return (
    <footer className="contactUs">
      <h3 className="needHelp">{getWord({ key: "help" })}</h3>

      <div className="contactUs-form">
        <div className="main-form">
          <input
            type="text"
            onChange={(e) => handleFormInputs("username", e.target && e.target.value)}
            value={form.username}
            placeholder={getWord({ key: "name" })}
            className="textBox"
          />
          <input
            type="text"
            value={form.email}
            onChange={(e) => handleFormInputs("email", e.target && e.target.value)}
            placeholder={getWord({ key: "email" })}
            className="textBox"
          />

        </div>
        <div className="commentSec">
          <textarea
            value={form.question}
            onChange={(e) => handleFormInputs("question", e.target && e.target.value)}
            className="textArea"></textarea>

          <button className="submitBtn" onClick={handleFormSubmission} >
            {showSuccess === Status.loading ? getWord({ key: "loading" }) : getWord({ key: "send_question" })}
          </button>
          <p className="help_form_done" style={{ display: showSuccess === Status.success ? "flex" : "none" }}>
            {getWord({ key: "done" })}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default HelpUs;
