import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.

    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error("ErrorBoundary", error, errorInfo);
  }
  classes = {
    container: {
      width: "100%",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    h1:{
      fontSize: "6rem"
    }
  };

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return  <div style={this.classes.container}>
      <p style={this.classes.h1}>500 Something went wrong.</p>
    </div>
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
