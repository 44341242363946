import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import CategoriesList from "../components/Categories";
import ErrorBoundary from "../components/ErrorBoundries";
import HelpUs from "../components/Help";
import Navbar from "../components/Navbar";
import Notification from "../components/Notification";
import { useUrlParams } from "../hooks/path";
import { Langs, useTranslate } from "../i18n";


const IndexPage = (props) => {
  const { lang = Langs.Arabic, country } = useUrlParams();
  const notifications = useSelector(state => state.notifications);
  React.useEffect(() => {
    document.body.setAttribute("dir", lang === Langs.Arabic ? "rtl" : "ltr");
  }, []);
  const getWord = useTranslate(lang);
  return (
    <ErrorBoundary>
      <div className="main-wrapper">
        <div className="main-banner">
          <Navbar />
          <h2 className="main-title">{getWord({ key: "app_name" })}</h2>
        </div>
        <div className="main-content">
          <CategoriesList />
        </div>
      </div>
      <HelpUs />
      {notifications.length > 0 && 
        <Notification >
          {notifications.map(n => <p key={n.id}>{n.message}</p>)}
        </Notification>
      }
    </ErrorBoundary>
  );
};

export default IndexPage;
