import { request } from "./request";

export const getAllOffers = async (country, category, page = "1") => {
  const offers = await request.get(`${country}/${category}/offers`, {
    page,
  });
  if (!offers) {
    return { data: [], links: [] };
  }
  return offers;
};

export const getBaseUrl = async () => {
  const response = await request.get("base_url");
  console.log(response);
  if (response && response.url) {
    return response.url;
  }
  return "https://";
};
