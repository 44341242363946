import { useDispatch } from "react-redux"
import { popNotification, pushNotification } from "../redux/notification/action";

export const useNotifications = (duration=2000) =>{
    const dispatch = useDispatch();
    
    const pushMessage = (message) =>{
        const id = Math.random();
        dispatch(pushNotification({id,message}));
        setTimeout(() => {
            dispatch(popNotification({id}));
        }, duration);
    }
    return {
        pushMessage
    }
}