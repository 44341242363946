import React from "react";

import closeBtn from "../../assets/images/closeBtn.svg";
import PdfIcon from "../../assets/images/pdf-icon.svg";
import ImageIcon from "../../assets/images/image-icon.svg";
import DownloadIcon from "../../assets/images/download-icon.svg";
import { Langs, useTranslate } from "../../i18n";
import { useUrlParams } from "../../hooks/path";
import { useBaseUrl, useOffers } from "../../hooks/offers";

const OffersList = ({ onClose, category }) => {
  const { lang } = useUrlParams();
  const baseUrl = useBaseUrl();
  const getWord = useTranslate(lang);
  const { offers, links, getPage } = useOffers(category.id);
  return (
    <div id="content-overlay" className="main-overlay contentList-main">
      <section className="contentList">
        <h4 className="overlayTitle">
          {category[lang === Langs.Arabic ? "name_ar" : "name_en"] || ""}
        </h4>
        <span className="closeBtn" onClick={onClose}>
          <img src={closeBtn} alt="" />
        </span>

        <ul className="pagingList-main">
          {(offers || []).map((o) => (
            <li key={o.id}>
              <div className="pagingList-title">
                <span>
                  <img
                    src={o.doc_type === "image" ? ImageIcon : PdfIcon}
                    alt=""
                  />
                </span>
                <span className="pagingTitle">
                  {o[lang === Langs.Arabic ? "title_ar" : "title_en"]}
                </span>
              </div>
              <div className="pagingList-desc">
                {o[lang === Langs.Arabic ? "desc_ar" : "desc_en"]}
              </div>
              <div className="pagingList-download">
                <span>
                  <img src={DownloadIcon} alt="" />
                </span>
                <span className="downloadTitle">
                  {getWord({ key: "download" })}
                </span>
                <a
                  href={
                    baseUrl +
                    (o.doc_path.startsWith("/") ? "" : "/") +
                    o.doc_path
                  }
                  target="_blank"
                  rel="noreferrer"
                  className="allLink"
                >
                  &nbsp;
                </a>
              </div>
            </li>
          ))}
        </ul>
        <ul className="paging-main">
          {(links || []).map((l, i) =>
            l.label === "&laquo; Previous" || l.label === "Next &raquo;" ? (
              <React.Fragment key={i}></React.Fragment>
            ) : (
              <li key={i}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  // eslint-disable-next-line no-script-url
                  href="#"
                  onClick={() => getPage(l.url)}
                  className={l.active ? "active" : ""}
                >
                  {l.label}
                </a>
              </li>
            )
          )}
        </ul>
      </section>
    </div>
  );
};

export default OffersList;
