import React from "react";

import classes from "./index.module.css";
import intigralLogo from "../../assets/images/Intigral.svg";
import downArrow from "../../assets/images/down-arrow.svg";
import PopupCountries from "./PopupCountries";
import { useUrlParams } from "../../hooks/path";
import { useCountries } from "../../hooks/country";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Langs, useTranslate } from "../../i18n";
import KSABG from "../../assets/images/ksa-BG.jpg";
import UAEBG from "../../assets/images/dubai-BG.jpg";
import WWBG from "../../assets/images/worldwide-BG.jpg";

const Navbar = () => {
  const { lang, country } = useUrlParams();
  const [showPopup, setShowPopup] = React.useState(!country);
  const countries = useCountries();
  const history = useHistory();
  const match = useRouteMatch();

  React.useEffect(() => {
    const current_country = countries.find((c) => +c.id === +country);
    if (
      !current_country &&
      countries &&
      countries.length > 0 &&
      match.path !== "/"
    ) {
      history.push("/404");
    }
    if (current_country && current_country.name_en) {
      const src =
        current_country.name_en.toLowerCase() === "ksa"
          ? KSABG
          : current_country.name_en.toLowerCase() === "uae"
          ? UAEBG
          : WWBG;

      document.querySelector(
        ".main-banner"
      ).style.backgroundImage = `url(${src})`;
    }
  }, [countries, country]);

  const getWord = useTranslate(lang);
  const handleLangNavigation = () => {
    if (lang === Langs.Arabic) {
      return "/en/" + country;
    }
    return "/" + country;
  };
  return (
    <>
      <div className={classes.nav}>
        <header className="header">
          <h1 className="main-logo">
            <a href="/">
              <img src={intigralLogo} alt="" />
            </a>
          </h1>
          <div className="main-nav">
            <ul className="main-navList">
              <li className="dropdown" onClick={() => setShowPopup((p) => !p)}>
                <span>
                  {countries.find((c) => +c.id === +country) &&
                    countries.find((c) => +c.id === +country)[
                      lang === Langs.Arabic ? "name_ar" : "name_en"
                    ]}
                </span>
                <span className="downArrow">
                  <img src={downArrow} alt="" />
                </span>
              </li>

              <li className="locale">
                <a href={handleLangNavigation()}>{getWord({ key: "lang" })}</a>
              </li>
            </ul>
          </div>
        </header>
      </div>
      {showPopup && <PopupCountries onClose={() => setShowPopup(false)} />}
    </>
  );
};

export default Navbar;
