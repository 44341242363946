import React from "react";
import { useCategories } from "../../hooks/category";
import { useUrlParams } from "../../hooks/path";
import { Langs } from "../../i18n";
import OffersList from "../OffersList";

const CategoriesList = (props) => {
  const [showOffers, setShowOffers] = React.useState({
    show: false,
    category: null,
  });
  const { lang } = useUrlParams();
  const categories = useCategories();
  return (
    <>
      <ul className="listContent">
        {categories.map((cat) => (
          <li
            key={cat.id}
            onClick={() => setShowOffers({ show: true, category: cat })}
          >
            <span className="list-counter">{cat.active_offers}</span>
            <span className="listText">
              {cat[lang === Langs.Arabic ? "name_ar" : "name_en"]}
            </span>
          </li>
        ))}
        {[...new Array(4 - (categories.length % 4))].map((_, i) => (
          <li key={i} className="listEmpty"></li>
        ))}
      </ul>
      {showOffers.show && (
        <OffersList
          category={showOffers.category}
          onClose={() => setShowOffers({ show: false })}
        />
      )}
    </>
  );
};

export default CategoriesList;
