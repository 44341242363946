import React from "react";
import { Link } from "react-router-dom";
import closeBtn from "../../../assets/images/closeBtn.svg";
import { useCountries } from "../../../hooks/country";
import { useUrlParams } from "../../../hooks/path";
import { Langs, useTranslate } from "../../../i18n";

const PopupCountries = ({ onClose }) => {
  const { lang, country } = useUrlParams();
  const countries = useCountries();
  const getWord = useTranslate(lang);
  const handleClosing = () => {
    if (!!country) {
      onClose();
    }
  };

  const handleCountryNavigation = (name_en) => {
    const country = countries.find(
      (c) => c.name_en.toLowerCase() === name_en.toLowerCase()
    );
    const base = lang === Langs.Arabic ? "/" : "/en/";
    if (country) {
      return base + country.id;
    }
    return base;
  };

  return (
    <div id="nav-overlay" className="main-overlay">
      <div className="overlayCopy">
        {/* <h4 className="overlayTitle">
          {lang === Langs.Arabic && <span>{getWord({ key: "offices" })}</span>}{" "}
          {getWord({ key: "company_name" })}{" "}
          {lang === Langs.English && <span>{getWord({ key: "offices" })}</span>}
        </h4> */}
        <span className="closeBtn" onClick={handleClosing}>
          <img src={closeBtn} alt="" />
        </span>
        <div className="overlayList">
          {countries.map((c) => (
            <div key={c.id} className={`overlayBox ${c.name_en.toUpperCase().replace(/ /g, '_')}`}>
              <Link
                to={() => handleCountryNavigation(c.name_en)}
                onClick={onClose}
              >
                {lang === Langs.Arabic ? c.name_ar : c.name_en}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PopupCountries;
