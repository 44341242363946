import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import IndexPage from "./pages";
import Page404 from "./pages/404";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/404" exact component={Page404} />
        <Route path="/:lang/:country" component={IndexPage} />
        <Route path="/:lang" component={IndexPage} />
        <Route path="/" component={IndexPage} />
      </Switch>
    </Router>
  );
}

export default App;
