/* eslint-disable import/no-anonymous-default-export */
import { ClEAR_ALL_NOTIFICATIONS, POP_NOTIFICATION, PUSH_NOTIFICATION } from "./action";

export default (state=[], action) =>{
 switch (action.type) {
     case PUSH_NOTIFICATION:
         return [...state, {id:action.id,message:action.message}];
    case POP_NOTIFICATION:
        return state.filter(s => s.id !== action.id);
    case ClEAR_ALL_NOTIFICATIONS:
        return [];
     default:
        return state;
 }
}