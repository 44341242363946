import React from "react";
import { getAllCountries } from "../api/country";

export const useCountries = () => {
  const [countries, setCountries] = React.useState([]);

  React.useEffect(() => {
    (async () => {
      const countries = await getAllCountries();
      if (Array.isArray(countries)) {
        setCountries(countries);
      } else {
        var tempArray = [];
        Object.keys(countries).map(k => {
          tempArray.push(countries[k]);
        });
        setCountries(tempArray);
      }
    })();
  }, []);

  return countries;
};
