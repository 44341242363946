import { Base_API_URL } from "../constants";

export const request = {
  get: async (path, queries = {}) => {
    const query = Object.keys(queries).reduce((p, c) => {
      return (p += `${c}=${queries[c]}&`);
    }, "?");
    const request = await fetch(`${Base_API_URL}${path}${query}`);
    try {
      console.log(path, query, request.status);
      return await request.json();
    } catch (error) {
      console.error(error);
      return null;
    }
  },
  post: async (path, data = {}) => {
    const request = await fetch(`${Base_API_URL}${path}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data),
    });
    try {
      console.log(path, request.status);
      return await request.json();
    } catch (error) {
      console.error(error);
      return null;
    }
  },
  delete: async (path, data = {}) => {
    const request = await fetch(`${Base_API_URL}${path}`, {
      method: "DELETE",
      body: JSON.stringify(data),
    });
    try {
      console.log(path, request.status);
      return await request.json();
    } catch (error) {
      console.error(error);
      return null;
    }
  },
  patch: async (path, data = {}) => {
    const request = await fetch(`${Base_API_URL}${path}`, {
      method: "PATCH",
      body: JSON.stringify(data),
    });
    try {
      console.log(path, request.status);
      return await request.json();
    } catch (error) {
      console.error(error);
      return null;
    }
  },
};
