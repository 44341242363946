import { useParams } from "react-router-dom";
import { Langs } from "../i18n";

export const useUrlParams = () => {
  const { lang, country } = useParams();
  if (!lang || +lang === 0 || !isNaN(+lang)) {
    return { lang: Langs.Arabic, country: lang };
  }
  return { lang: Langs.English, country };
};
