import { combineReducers, createStore } from "redux";
import global from "./global";
import notifications from "./notification";

export default createStore(
  combineReducers({
    global,
    notifications
  })
);
