import React from "react";
import { getAllOffers, getBaseUrl } from "../api/offer";
import { useUrlParams } from "./path";

export const useOffers = (category) => {
  const { country } = useUrlParams();
  const [offers, setOffers] = React.useState([]);
  const [page, setPage] = React.useState();
  function getPage(pageUrl) {
    const url = new URL(pageUrl);
    const params = new URLSearchParams(url.search);
    const page = params.get("page");
    setPage(page);
  }
  React.useEffect(() => {
    (async () => {
      const offers = await getAllOffers(country, category, page);
      setOffers(offers);
    })();
  }, [country, category, page]);

  return {
    offers: offers.data,
    links: offers.links,
    getPage,
  };
};

export const useBaseUrl = () => {
  const [url, setUrl] = React.useState("https://");
  React.useEffect(() => {
    (async () => {
      const url = await getBaseUrl();
      setUrl(url.endsWith("/") ? url.slice(0, url.length - 1) : url);
    })();
  }, []);
  return url;
};
