

export const Langs = {
    English: "en",
    Arabic: "ar"
}

export const useTranslate = (lang=Langs.Arabic)=>{
    const TranslationFile = require(`./${lang}.json`)
    const getWord = ({key, params={}})=>{
        let statement = TranslationFile[key];
        Object
            .keys(params)
            .forEach((pkey)=>{
                statement = statement.replace(new RegExp(`{{${pkey}}}`,"gi"),params[pkey]);
        })
        return statement;
    }

    return getWord;
}