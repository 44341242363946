export const PUSH_NOTIFICATION = "PUSH_NOTIFICATION";
export const POP_NOTIFICATION = "POP_NOTIFICATION";
export const ClEAR_ALL_NOTIFICATIONS = "ClEAR_ALL_NOTIFICATIONS";


export const pushNotification = ({id,message}) => {
    return {
        type: PUSH_NOTIFICATION,
        id,
        message
    }
}
export const popNotification = ({id}) => {
    return {
        type: POP_NOTIFICATION,
        id
    }
}
export const clearAllNotifications = () => {
    return {
        type: ClEAR_ALL_NOTIFICATIONS,
    }
}