import React from "react";
import { getAllCategories } from "../api/category";
import { useUrlParams } from "./path";

export const useCategories = () => {
  const { country } = useUrlParams();
  const [categories, setCategories] = React.useState([]);
  React.useEffect(() => {
    (async () => {
      const categories = await getAllCategories(country);
      if(Array.isArray(categories)){
        setCategories(categories);
      } else {
        var tempArray = [];
        Object.keys(categories).map(k => {
          tempArray.push(categories[k]);
        });
        setCategories(tempArray);
      }
    })();
  }, [country]);

  return categories;
};
